import React, { useContext, useRef, useState } from 'react';
import cx from 'utils/classnames';
import { CSSTransition } from 'react-transition-group';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import useOutsideClick from 'hooks/custom/useOutsideClick';
import MultiSchoolSelectContext from 'components/base/inputs/multiSchoolSelect/multiSchoolSelectContext';
import { UseMultiSchoolSelect } from 'types';
import SchoolLogo from '../SchoolLogo';

import { SelectionCardExpanded } from './SelectionCardExpanded';
import { SelectionCardActions } from './SelectionCardActions';

interface SelectionCardProps {
  id: string;
}
export function SelectionCard(props: SelectionCardProps): React.JSX.Element {
  const { id } = props;

  const selectionCardRef = useRef(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { state, handleCloseCard } = useContext<UseMultiSchoolSelect>(
    MultiSchoolSelectContext
  );

  const {
    isExpanded,
    isSkipped,
    isSubmitted,
    isAccepted,
    isShowingDetails,
    selectedProgram,
    schoolInfo: { schoolLogo, featuredImage, label },
  } = state[id];

  function _handleOutsideClick(): void {
    if (isTransitioning) {
      return;
    }
    if (isExpanded || isShowingDetails) {
      handleCloseCard(id);
    }
  }

  useOutsideClick(selectionCardRef, _handleOutsideClick);

  function _handleOnTransitionEnd(): void {
    setIsTransitioning(false);
  }

  function _handleOnTransitionStart(): void {
    setIsTransitioning(true);
  }

  const selectionCardClass = cx('selectionCard', {
    'selectionCard--isShowingDetails': isShowingDetails,
    'selectionCard--isSelected': !isSkipped,
    'selectionCard--isSubmitted': isSubmitted,
    'selectionCard--isAccepted': isAccepted,
    'selectionCard--isExpanded': isExpanded,
  });

  return (
    <CSSTransition
      key={id}
      timeout={300}
      in={isExpanded || isShowingDetails}
      onEntering={_handleOnTransitionStart}
      onEntered={_handleOnTransitionEnd}
      onExiting={_handleOnTransitionStart}
      onExited={_handleOnTransitionEnd}
    >
      <div className={selectionCardClass} ref={selectionCardRef}>
        <div className="selectionCard__front">
          <div className="selectionCard__featuredImage">
            <img src={featuredImage.mobile} alt="" />
          </div>
          <div className="selectionCard__body">
            <div className="selectionCard__meta">
              <div className="selectionCard__logo">
                <SchoolLogo
                  logo={schoolLogo}
                  className="selectionCard__logoImg"
                  alt={`${label} logo`}
                />
              </div>
            </div>
            <div className="selectionCard__content">
              <h4 className="selectionCard__title">{selectedProgram.label}</h4>
              <div className="selectionCard__subTitle">
                <Wysiwyg content={label} />
              </div>
            </div>
            <div className="selectionCard__actions">
              <SelectionCardActions
                id={id}
                showSelect={false}
                showCheckbox
                showSubmit={false}
              />
            </div>
          </div>
        </div>
        {isExpanded && !isShowingDetails && <SelectionCardExpanded id={id} />}
      </div>
    </CSSTransition>
  );
}
