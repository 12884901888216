import { FIELD_NAMES } from 'consts';
import React from 'react';
import { BasicFormWizardContext as ContextType } from 'types';
import { initialState } from 'hooks/custom/forms/basic-wizard/reducer';

const basicFormWizardContext: ContextType = {
  allQuestionsInForm: null,
  formValues: {} as Record<FIELD_NAMES, unknown>,
  formStatus: { isProcessing: initialState.formStatus.isProcessing },
  handleChange: () => { },
  formConfigs: {
    schoolCode: '',
    variant: '',
  },
  fieldNameMap: {} as ContextType['fieldNameMap'],
};

const BasicFormWizardContext = React.createContext<ContextType>(
  basicFormWizardContext
);

export default BasicFormWizardContext;
