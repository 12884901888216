import { LogError } from './logging';

const MAX_POLLING_COUNT = 20;
const POLLING_INTERVAL_MS = 3000;

export function getPollingHelper({
  transactionId,
  resultsRequest,
  shouldPollFunc,
  maxPollingCount = MAX_POLLING_COUNT,
  pollingIntervalMs = POLLING_INTERVAL_MS,
}) {
  let resultsCallCount = 0;
  let pollingFailures = 0;
  let _shouldPollFunc = shouldPollFunc;
  let _transactionId = transactionId;
  let _resultsRequest = resultsRequest;

  function pollResults(shouldPoll = true, isInitialPoll = false) {
    if (!_transactionId) {
      throw new Error('transactionId is required');
    } else if (!_resultsRequest) {
      throw new Error('resultsRequest is required');
    } else if (!_shouldPollFunc) {
      throw new Error('shouldPollFunc is required');
    }

    if (!shouldPoll) {
      return Promise.resolve(false);
    }
    return new Promise((resolve) => {
      setTimeout(
        async () => {
          resultsCallCount += 1;
          const resultsResponse = await _resultsRequest({
            transactionId: _transactionId,
          }).catch((error) => {
            pollingFailures += 1;
            if (pollingFailures > 3) {
              LogError('Polling transaction failed', { error: error?.message });
            }
          });
          resolve(
            await pollResults(
              _shouldPollFunc(resultsResponse) &&
                resultsCallCount < maxPollingCount
            )
          );
        },
        isInitialPoll ? 0 : pollingIntervalMs
      );
    });
  }

  return {
    startPolling: () => {
      if (!_transactionId) {
        LogError('No transaction Id was returned from submit request');
      }
      return pollResults(
        /* should poll */ Boolean(_transactionId),
        /* isInitialPoll */ true
      );
    },
    setShouldPoll: (shouldPollFuncReplacement) => {
      _shouldPollFunc = shouldPollFuncReplacement;
    },
    setTransactionId: (transactionIdReplacement) => {
      _transactionId = transactionIdReplacement;
    },
    setResultsRequest: (resultsRequestReplacement) => {
      _resultsRequest = resultsRequestReplacement;
    },
    getTransactionId: () => {
      return _transactionId;
    },
  };
}

/**
 *
 * @param {Function} request - a function that returns a request
 * @param {String} opts.transactionKey - the attribute in the request that will be use as the transaction id
 * @param {Function} opts.resultsRequest - function that calls the get transaction endpoint
 * @param {Function} opts.shouldPollFunc - function that should return false when we want to stop polling
 * @example see unit tests
 */
export async function requestWithTransaction(request, opts) {
  const {
    transactionKey,
    resultsRequest,
    shouldPollFunc,
    maxPollingCount = MAX_POLLING_COUNT,
    pollingIntervalMs = POLLING_INTERVAL_MS,
  } = opts;

  if (!transactionKey || !request) {
    throw new Error('transactionKey and request are required');
  }

  const requestResults = await request({
    maxRetryAttempts: 3,
    waitTimeBetweenFails: 2000,
  });

  return getPollingHelper({
    transactionId: requestResults[transactionKey],
    resultsRequest,
    shouldPollFunc,
    maxPollingCount,
    pollingIntervalMs,
  });
}
