import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'utils/classnames';
import { capitalizeFirstLetter } from 'utils/stringHelpers';

import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import { CtaPropTypes, WPImagesPropType } from 'utils/propTypes';
import CtaButton from 'components/blocks/ctaButton/CtaButton';
import ResponsiveImage from 'components/base/responsiveImage/ResponsiveImage';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import VideoEmbed from 'components/base/videoEmbed/VideoEmbed';
import Stats from 'components/base/stats/Stats';

const MEDIA_WIDTH_MAP = { small: '33vw', medium: '50vw', large: '66vw' };

const BillBoard = (props) => {
  const {
    background,
    content,
    cta,
    customCssClass,
    images,
    imageAlignment = 'Top',
    mediaType,
    isReversed,
    mediaWidth,
    subTitle,
    stats: { showStats, statsList },
    title,
    variation,
    videoEmbedId,
    wysiwyg,
  } = props;

  // TODO: Make these values configurable in WP
  const responsiveImageSizes = {
    MOBILE: '100vw',
    TABLET: '100vw',
    DESKTOP: MEDIA_WIDTH_MAP[mediaWidth],
    DEFAULT: '100vw',
  };

  const getBillBoardClassName = () => {
    const classes = {
      billBoard: true,
      [`billBoard--media${capitalizeFirstLetter(imageAlignment)}`]: true,
      [`billBoard--variation${
        variation ? capitalizeFirstLetter(variation) : ''
      }`]: !!variation,
      [`billBoard--background${
        background ? capitalizeFirstLetter(background) : ''
      }`]: !!background,
      [`billBoard--mediaWidth${
        mediaWidth ? capitalizeFirstLetter(mediaWidth) : ''
      }`]: !!mediaWidth,
      [`billBoard--isReversed`]: isReversed,
      [`billBoard--hasWysiwyg`]: mediaType === 'wysiwyg',
      [`billBoard--hasVideo`]: mediaType === 'video',
      [`billBoard--${customCssClass}`]: !!customCssClass,
    };
    return classNames(classes);
  };

  function getMediaContent() {
    if (mediaType === 'image' && images.image && images.image.url) {
      return (
        <div className="billBoard__media">
          <DefaultErrorBoundary meta={{ component: 'ResponsiveImage' }}>
            <ResponsiveImage
              images={images}
              sizes={responsiveImageSizes}
              loading="lazy"
            />
          </DefaultErrorBoundary>
        </div>
      );
    }
    if (mediaType === 'wysiwyg' && wysiwyg && wysiwyg.length) {
      return (
        <div className="billBoard__media">
          <Wysiwyg content={wysiwyg} />
        </div>
      );
    }
    if (mediaType === 'video' && videoEmbedId) {
      return (
        <div className="billBoard__media">
          <VideoEmbed embedId={videoEmbedId} />
        </div>
      );
    }
    return null;
  }

  return (
    <div className={getBillBoardClassName()}>
      {variation === 'angled' && <span className="billBoard__bkgrd" />}
      <div className="billBoard__inner">
        {getMediaContent()}
        <div className="billBoard__content">
          {title && (
            <h1 className="billBoard__title">
              <Wysiwyg content={title} />
            </h1>
          )}
          {subTitle && (
            <h2 className="billBoard__subTitle">
              <Wysiwyg content={subTitle} />
            </h2>
          )}
          {content && (
            <div className="billBoard__copy">
              <Wysiwyg content={content} />
            </div>
          )}
          {cta.useCTA && (
            <div className="billBoard__cta">
              <CtaButton {...cta} />
            </div>
          )}
        </div>
        {showStats && statsList.length > 0 && (
          <div className="billBoard__stats">
            <Stats stats={statsList} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BillBoard;

BillBoard.propTypes = {
  background: PropTypes.string,
  content: PropTypes.string,
  customCssClass: PropTypes.string,
  cta: CtaPropTypes,
  images: WPImagesPropType,
  imageAlignment: PropTypes.string,
  isReversed: PropTypes.bool,
  mediaWidth: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  variation: PropTypes.string,
  wysiwyg: PropTypes.string,
  mediaType: PropTypes.string,
  videoEmbedId: PropTypes.string,
  stats: PropTypes.shape({
    showStats: PropTypes.bool,
    statsList: PropTypes.arrayOf(
      PropTypes.shape({ title: PropTypes.string, subTitle: PropTypes.string })
    ),
  }),
};
