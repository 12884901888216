import React from 'react';

import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';

export function Terms({ terms }: { terms: string }): React.JSX.Element {
  return (
    <div className="terms">
      <Wysiwyg content={terms} />
    </div>
  );
}
