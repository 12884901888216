import { useEffect, useReducer } from 'react';
import { LogError, LogWarning } from 'utils/logging';
import {
  APIMetaDataInfo,
  MultiSchoolSelectAction,
  MultiSchoolSelectActionTypes,
  MultiSchoolSelectionState,
  QuestionRepliesStructureForBackend,
  Program,
  School,
  UseMultiSchoolSelect,
} from 'types';
import { getNewLeadSubmitBatchId } from 'app-requests/triadms-apis/getNewLeadSubmitBatchId';
import { submitSingleMicroPortalLead } from 'app-requests/triadms-apis/processMicroPortalLead';
import { tokenReplacement } from 'hooks/custom/useTokenReplacement';

const {
  EXPAND_CARD,
  SKIP_CARD,
  TOGGLE_SKIP,
  ACCEPT_TERMS,
  TOGGLE_DETAILS,
  CLOSE_CARD,
  UPDATE_PROGRAM,
  EXPAND_NEXT_CARD,
  INITIALIZE,
} = MultiSchoolSelectActionTypes;

const initialState: MultiSchoolSelectionState = {};
let isInitialized = false;

/**
 * Updates the state of a MultiSchoolSelect component based on the action that
 * is passed in.
 */
function reducer(
  state: MultiSchoolSelectionState,
  action: MultiSchoolSelectAction
): MultiSchoolSelectionState {
  const { type, payload } = action;
  const {
    id = '',
    program,
    schools,
    handleFormFieldChange,
    phoneNumber,
    disclaimerText,
  } = payload;

  const updatedState = { ...state };
  const schoolReferenced = updatedState[id];

  if (!isInitialized && type !== INITIALIZE) {
    LogError(
      `MultiSchoolSelectionState not initialized. Please initialize then dispatch "${type}"`
    );
  }

  switch (type) {
    // TODO [T1-11709]: Write Unit Tests for Micro Portal functionality
    case INITIALIZE: {
      if (!schools || !schools.length) {
        LogError('MultiSchoolSelectionState initialized without schools');
        return state;
      }

      isInitialized = true;

      const initializedState = schools.reduce(
        (acc: MultiSchoolSelectionState, school: School) => {
          acc[school.id] = {
            isSelected: true,
            isExpanded: false,
            isAccepted: false,
            isSkipped: false,
            isSubmitted: false,
            isShowingDetails: false,
            selectedProgram: school.originalSelectedProgram,
            schoolInfo: {
              ...school,
              terms: tokenReplacement(disclaimerText as string, {
                '{SELECTED_SCHOOL_NAME}': school.label,
                '{PHONE}': phoneNumber,
              }),
            },
          };
          return acc;
        },
        {}
      );

      handleFormFieldChange?.(initializedState);
      return initializedState;
    }

    case SKIP_CARD: {
      schoolReferenced.isSkipped = true;
      schoolReferenced.isExpanded = false;
      handleFormFieldChange?.(updatedState);
      return updatedState;
    }

    case TOGGLE_SKIP: {
      schoolReferenced.isSkipped = !schoolReferenced.isSkipped;
      schoolReferenced.isExpanded = false;
      handleFormFieldChange?.(updatedState);
      return updatedState;
    }

    case EXPAND_CARD: {
      // Minimize all cards
      Object.keys(updatedState).forEach((schoolId) => {
        updatedState[schoolId].isExpanded = false;
        updatedState[schoolId].isShowingDetails = false;
      });

      // Only expand if the card is selected and not skipped
      if (schoolReferenced.isSelected) {
        schoolReferenced.isExpanded = true;
      }

      return updatedState;
    }

    case EXPAND_NEXT_CARD: {
      const nextSchoolId = Object.keys(state).find(
        (schoolId) =>
          state[schoolId].isSelected &&
          !state[schoolId].isAccepted &&
          !state[schoolId].isSkipped &&
          !state[schoolId].isExpanded
      );

      // Minimize all cards
      Object.keys(updatedState).forEach((schoolId) => {
        updatedState[schoolId].isExpanded = false;
        updatedState[schoolId].isShowingDetails = false;
      });

      // Expand the next eligible card
      if (nextSchoolId) {
        updatedState[nextSchoolId].isExpanded = true;
      }

      return updatedState;
    }

    case ACCEPT_TERMS: {
      const nextSelectedSchoolId = Object.keys(state).find(
        (schoolId) =>
          state[schoolId].isSelected &&
          !state[schoolId].isAccepted &&
          !state[schoolId].isSkipped &&
          schoolId !== id
      );

      // Minimize all cards
      Object.keys(updatedState).forEach((schoolId) => {
        updatedState[schoolId].isExpanded = false;
        updatedState[schoolId].isShowingDetails = false;
      });

      // Update the state for the current school
      schoolReferenced.isAccepted = true;
      schoolReferenced.isExpanded = false;
      schoolReferenced.isSubmitted = true;

      // If there's a next school to expand, update its state
      if (nextSelectedSchoolId) {
        updatedState[nextSelectedSchoolId].isExpanded = true;
      }

      handleFormFieldChange?.(updatedState);
      return updatedState;
    }

    case TOGGLE_DETAILS: {
      schoolReferenced.isShowingDetails = !schoolReferenced.isShowingDetails;
      return updatedState;
    }

    case CLOSE_CARD: {
      schoolReferenced.isExpanded = false;
      schoolReferenced.isShowingDetails = false;
      return updatedState;
    }

    case UPDATE_PROGRAM: {
      if (program) {
        schoolReferenced.selectedProgram = program;
      }
      return updatedState;
    }

    default:
      LogWarning(`MultiSchoolSelection Unknown action type: "${type}"`);
      return state;
  }
}

export function useMultiSchoolSelect(
  schools: School[],
  handleFormFieldChange: (updatedFieldValue: MultiSchoolSelectionState) => void,
  disclaimerText: string,
  userPhoneNumber: string
): UseMultiSchoolSelect {
  const [state, dispatch] = useReducer(reducer, initialState);

  function handleSelectSchool(id: string): void {
    dispatch({ type: EXPAND_CARD, payload: { id } });
  }

  function handleSkipSchool(id: string): void {
    dispatch({ type: SKIP_CARD, payload: { id, handleFormFieldChange } });
    dispatch({ type: EXPAND_NEXT_CARD, payload: {} });
  }

  function handleToggleSkip(id: string): void {
    dispatch({ type: TOGGLE_SKIP, payload: { id, handleFormFieldChange } });
  }

  async function handleAcceptTerms(
    id: string,
    questionReplies: QuestionRepliesStructureForBackend,
    metaData: APIMetaDataInfo
  ): Promise<void> {
    dispatch({ type: ACCEPT_TERMS, payload: { id, handleFormFieldChange } });
    const batchId = await getNewLeadSubmitBatchId();
    await submitSingleMicroPortalLead(
      batchId,
      state[id].schoolInfo.impressionGuid,
      questionReplies,
      metaData,
      state[id].selectedProgram.value
    );
    // TODO [T1-11760]: We want to poll right after the first submit
    // startLeadSubmitPolling(batchId);
  }

  function handleNextClick(): void {
    dispatch({ type: EXPAND_NEXT_CARD, payload: {} });
  }

  function handleToggleDetails(id: string): void {
    dispatch({ type: TOGGLE_DETAILS, payload: { id } });
  }

  function handleCloseCard(id: string): void {
    dispatch({ type: CLOSE_CARD, payload: { id } });
  }

  function handleProgramChange(id: string, program: Program): void {
    dispatch({ type: UPDATE_PROGRAM, payload: { id, program } });
  }

  function initializeState(
    initialSchools: School[],
    phoneNumber: string,
    disclaimer: string
  ): void {
    dispatch({
      type: INITIALIZE,
      payload: {
        schools: initialSchools,
        handleFormFieldChange,
        phoneNumber,
        disclaimerText: disclaimer,
      },
    });
  }

  useEffect(() => {
    if (!userPhoneNumber || !disclaimerText) {
      LogError(
        'useMultiShoolSelect: User phone number or disclaimer success text not provided'
      );
    }

    initializeState(schools, userPhoneNumber, disclaimerText);
  }, [schools, userPhoneNumber, disclaimerText]);

  return {
    state,
    handleSelectSchool,
    handleSkipSchool,
    handleToggleSkip,
    handleAcceptTerms,
    handleNextClick,
    handleToggleDetails,
    handleCloseCard,
    handleProgramChange,
  };
}
