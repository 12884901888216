import React, { useCallback, useContext, useEffect, useState } from 'react';
import { QUESTION_IDS } from 'consts';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { SelectionCard } from 'components/form-wizards/micro-portal/SelectionCard/SelectionCard';
import SchoolLogo from 'components/form-wizards/micro-portal/SchoolLogo';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import { MultiStepLoader } from 'components/base/inputs/multiSchoolSelect/MultiStepLoader';
import {
  MultiSchoolSelectionState,
  MultiSchoolSelectProps,
  SchoolSelectionState,
} from 'types';
import { useMultiSchoolSelect } from './useMultiSchoolSelect';
import MultiSchoolSelectContext from './multiSchoolSelectContext';

const { PRIMARY_PHONE } = QUESTION_IDS;

/**
 * Renders a list of SelectionCards based on the provided options.
 * Additionally renders a "cart" that displays the schools that have been submitted.
 */
export function MultiSchoolSelect(
  props: MultiSchoolSelectProps
): React.JSX.Element {
  const { options, name, error } = props;
  const {
    handleChange,
    formValues,
    allQuestionsInForm,
    formStatus: { isProcessing },
  } = useContext(BasicFormWizardContext);
  const {
    siteMeta: { disclaimerSuccessText },
  } = useContext(GlobalContext);

  let phoneNumber = '';
  if (allQuestionsInForm?.[PRIMARY_PHONE]?.name) {
    const phoneFieldName = allQuestionsInForm[PRIMARY_PHONE].name;
    phoneNumber = formValues[phoneFieldName] as string;
  }

  const LOADER_MIN_DURATION = 3000;

  const [showLoader, setShowLoader] = useState(true);

  const updateMultiSchoolSelectFormValue = useCallback(
    (multiSchoolSelectFormValue: MultiSchoolSelectionState) => {
      const formValue = Object.keys(multiSchoolSelectFormValue).reduce(
        // Only storing minimal state for validation
        (acc, key: string) => {
          acc[key] = {
            isAccepted: multiSchoolSelectFormValue[key].isAccepted,
            isSkipped: multiSchoolSelectFormValue[key].isSkipped,
          };
          return acc;
        },
        {} as Record<string, Partial<SchoolSelectionState>>
      );

      // setTimeout because this need to be deferred to after MultiSchoolSelect is rendered
      setTimeout(() => handleChange({ value: formValue }, { name }));
    },
    [name]
  );

  const multiSchoolSelectState = useMultiSchoolSelect(
    options,
    updateMultiSchoolSelectFormValue,
    disclaimerSuccessText,
    phoneNumber
  );

  const { state, handleNextClick } = multiSchoolSelectState;
  const { actions, flags } = useContext(GlobalContext);

  useEffect(() => {
    const isAnyCardExpanded = Object.values(state).some(
      (school) => school.isExpanded
    );

    if (flags.hasExpandedCard !== isAnyCardExpanded) {
      actions.updateGlobalFlags({
        hasExpandedCard: isAnyCardExpanded,
      });
    }
  }, [state, flags, actions]);

  const shouldShowCart = Object.values(state).some(
    (school) => school.isSubmitted
  );

  useEffect(() => {
    if (error) {
      handleNextClick();
    }
  }, [error]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (isProcessing) {
      setShowLoader(true);
    } else {
      timer = setTimeout(() => {
        setShowLoader(false);
      }, LOADER_MIN_DURATION);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isProcessing]);

  if (showLoader) {
    return (
      <MultiStepLoader
        duration={LOADER_MIN_DURATION}
        isProcessing={isProcessing}
      />
    );
  }

  return (
    <MultiSchoolSelectContext.Provider value={multiSchoolSelectState}>
      <div
        className={
          flags.hasExpandedCard
            ? 'multiSchoolSelect multiSchoolSelect--hasExpanded'
            : 'multiSchoolSelect'
        }
      >
        <div className="multiSchoolSelect__inner">
          <div className="multiSchoolSelect__title">
            Feel free to adjust your selections
          </div>
          <ul className="multiSchoolSelect__list">
            {Object.values(state).map((school) => (
              <li
                className="multiSchoolSelect__listItem"
                key={school.schoolInfo.id}
              >
                <SelectionCard id={school.schoolInfo.id} />
              </li>
            ))}
          </ul>
        </div>
        <div
          className={
            shouldShowCart
              ? 'multiSchoolSelect__cart multiSchoolSelect__cart--hasItems'
              : 'multiSchoolSelect__cart'
          }
        >
          <div className="multiSchoolSelect__cartHeading">
            Congratulations, you&rsquo;ve been submitted to these schools:
          </div>
          <div className="multiSchoolSelect__cartSubHeading">
            A representative will reach out shortly
          </div>
          <ul className="multiSchoolSelect__cartList">
            {Object.keys(state)
              .filter((schoolId) => state[schoolId].isSubmitted)
              .map((schoolId) => (
                <li
                  className="multiSchoolSelect__cartItem"
                  key={`submittedSchool_${schoolId}`}
                >
                  <SchoolLogo
                    logo={state[schoolId].schoolInfo.schoolLogo}
                    className="selectionCard__logoImg"
                    alt={`${state[schoolId].schoolInfo.label} logo`}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </MultiSchoolSelectContext.Provider>
  );
}
