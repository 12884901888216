/**
 * @summary this file deals with everything around click portal schools. From requesting schools to managing their impression tracking
 */
import { CLICK_PORTAL_SEARCH_FILTERS, QUERY_PARAMS, SITE_TYPE } from 'consts';
import get from 'lodash/get';
import isBrowser from 'utils/isBrowser';
import { wait } from 'utils/generalUtils';
import { getUserSessionId } from 'utils/analyticsHelpers';
import { getFormSelectionObjectsFromQuery } from 'components/form-wizards/click-portal-search/utils/getFormSelectionObjects';
import { getSchoolLogoUrlMap } from 'utils/imageHelpers';
import { LogError, LogWarning } from '../utils/logging';
import request from '../utils/request';
import { TRIAD_PROXY_ROUTE, TRIADMS_BACKEND } from './apiConstants';

const { DEGREES, CONCENTRATIONS, SUBJECTS, FILTER_KEY } =
  CLICK_PORTAL_SEARCH_FILTERS;

export const schoolImpressionPayloadQueue = {};

/**
 * @summary this will get the click portal school results
 * @param {Object} filters
 * @see CLICK_PORTAL_SEARCH_FILTERS in consts.js
 * @private
 */
export function generateSchoolListingCacheKey(filters) {
  Object.keys(filters).forEach((key) => {
    if (![DEGREES, CONCENTRATIONS, SUBJECTS, FILTER_KEY].includes(key)) {
      LogError(
        'We have added an Unknown filter that needs to be present in the cache key'
      );
    }
  });

  return [
    filters[DEGREES]?.value,
    filters[SUBJECTS]?.value,
    filters[CONCENTRATIONS]?.value,
    filters[FILTER_KEY],
  ]
    .map((value) => value || 'undefined')
    .join('|');
}

/**
 * @summary its possible we don't have the impression key at the time we render the  schools. In this case queue up the impressions
 * @param {Object} programPayload - see trackClickPortalImpression on the payload we send to backend
 * @private
 */
function _addToImpressionQueue(schoolGuid, programPayload) {
  if (schoolGuid && programPayload.body) {
    schoolImpressionPayloadQueue[schoolGuid] = programPayload;
  }
}

/**
 * @summary its possible that some impressions were queued up before the schools were rendered. In this case send them now.
 * @param {Object} schools - see return results from handleImpressionKeyMapping
 * @private
 */
function _processImpressionQueue(schools = []) {
  try {
    schools.forEach((school) => {
      const payload = schoolImpressionPayloadQueue[school.id];

      if (payload) {
        const _payload = {
          ...payload,
          body: {
            ...payload.body,
            schoolImpressionGuid: school.impressionGuid,
          },
        };

        request(_payload).catch((error) => {
          LogError(error, {
            impressionGuid: school.impressionGuid,
          });
        });
      }

      delete schoolImpressionPayloadQueue[school.id];
    });
  } catch (error) {
    LogError(`Failed to process impression Queue ${error.message}`);
  }
}

/**
 * @summary this will get the click portal school results
 * @param {Object} filters - see CLICK_PORTAL_SEARCH_FILTERS in consts.js
 * @param {Boolean} shouldRequestCacheableData - tell backend what type of impression to send, cache key or all new value impression guids
 * @param {Boolean} isControlGroup - we are telling backend if we want a control group
 * @private
 */
async function _getSchoolListings(
  schoolFilters = {},
  isControlGroup,
  siteType,
  sessionId,
  meta = {}
) {
  const { isPersonalized = false, geoLocation = {}, originalUrl = '' } = meta;
  const path = isBrowser() ? TRIAD_PROXY_ROUTE : TRIADMS_BACKEND;
  const endpointUrl =
    siteType === 'clickPortal'
      ? `${path}/ClickPortal/GetSchoolListings`
      : `${path}/microportal/GetMPSchoolListings`;
  // Note this will only be cached on browser
  const cacheKey = isBrowser()
    ? generateSchoolListingCacheKey(schoolFilters)
    : null;

  const shouldRequestImpressions = isBrowser()
    ? Boolean(getUserSessionId())
    : false;

  // Check if the filterKey contains '&' and log an error
  // error logs are showing filterKeys being passed that are concatenated with other params
  const filterKey = schoolFilters[FILTER_KEY];

  if (filterKey && (filterKey.includes('&') || filterKey.includes('&amp;'))) {
    LogError('Invalid FilterKey: contains the & character', {
      filterKey,
      originalUrl,
    });
  }

  return request({
    cacheKey,
    method: 'post',
    url: endpointUrl,
    body: {
      // if no session then ClickPortalCacheOnly else if session ClickPortalWithCache
      impressionType: shouldRequestImpressions
        ? 'ClickPortalWithCache'
        : 'ClickPortalCacheOnly',
      FilterKey: filterKey,
      DegreeType: schoolFilters[DEGREES]?.value,
      ParentCategory: schoolFilters[SUBJECTS]?.value,
      Category: schoolFilters[CONCENTRATIONS]?.value,
      isControlGroup,
      sessionId,
      includeGeoLocation: isPersonalized, // when called from browser this will tell server to include geoLocation
      // See [...endpoints].js for how this gets added from browser
      geoLocation: isBrowser() ? {} : geoLocation, // when called from server we pass it in directly,
      originalUrl,
      requestType:
        siteType === SITE_TYPE.MICRO_PORTAL ? 'MicroClickportal' : '',
    },
  })
    .then(async (res) => {
      if (!res.IsValid) {
        throw new Error(
          res.Errors[0] ||
            'Get School Listing API Failed but no backend error returned'
        );
      }

      const isFromCache = Boolean(res.cacheKey);

      const results = res.Listings.map((school) => ({
        id: school.value,
        name: school.label,
        schoolLogoUrl: getSchoolLogoUrlMap(school.schoolImages),
        description:
          get(school, 'schoolDesc[0]') ||
          'Description not available please check back soon.',
        rating: school.rating || null,
        url: school.destinationUrl || null,
        learningType: school.learningEnvironment || null,
        isOffsiteConversion: school.IsOffsiteConversion || false,
        highlights: [school.highlights || ''],
        selectedProgram: school.selectedProgram || {},
        otherPrograms: school.programs || [],
        filteredProgramCount: school.filteredProgramCount || null,
        programCount: school.programCount || null,
        matchingCategory: school.MatchingCategory || null,
        schoolCode: school.schoolCode || null,
        revenue: school.Revenue || 0,
        // If not from cache and their is an impressionGuid set to GUID else null out
        impressionGuid: isFromCache ? null : get(school, 'impressionGuid', ''),
        filterTagName: school.FilterTagName || '',
      }));

      const hasImpressionGuids = !isFromCache && shouldRequestImpressions;

      if (!hasImpressionGuids && !res.ImpressionKey) {
        LogError(
          'School results are loaded but their are both no impression guids and we have no cache key to fetch them.'
        );
      }

      if (isFromCache) {
        await wait(500);
      }

      return {
        hasImpressionGuids,
        title: res.Headline1 || '',
        subTitle: res.Headline2 || '',
        impressionCacheKey: res.ImpressionKey || '',
        results,
        hasTags: res.HasTags || false,
        filterKeyDerivedValues: {
          hasDerivedValues: Boolean(
            res.ParentCategory && res.DegreeType && res.Category
          ),
          [QUERY_PARAMS.PARENT_CAT_GUID_PARAM]: res.ParentCategory || null,
          [QUERY_PARAMS.DEGREE_GUID_PARAM]: res.DegreeType || null,
          [QUERY_PARAMS.CATEGORY_GUID_PARAM]: res.Category || null,
        },
      };
    })
    .catch((error) => {
      LogError(error, { schoolFilters });
      throw error;
    });
}

/**
 * @summary this function will request impression GUIDs from the backend and attach them to the school listings results
 * @param {Object} schoolListingResults - see the return object of schoolListingsResultsHandler function
 */
export function handleImpressionKeyMapping(schoolListingResults) {
  const { impressionCacheKey, results } = schoolListingResults;

  if (!impressionCacheKey) {
    LogWarning('Impression Key not returned from backend');
    return Promise.resolve(schoolListingResults);
  }

  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/ClickPortal/GetSchoolImpressions`,
    body: {
      impressionKey: impressionCacheKey,
    },
  }).then(({ SchoolImpressions }) => {
    const mappedResults = results.map((result) => {
      const mappedResult = { ...result };
      const impressionGuid = SchoolImpressions?.find(
        (impression) => impression.SchoolGuid === result.id
      )?.value;

      mappedResult.impressionGuid = impressionGuid;
      return mappedResult;
    });

    _processImpressionQueue(mappedResults);
    return { ...schoolListingResults, results: mappedResults };
  });
}

/**
 * @summary this function will track and impression on the backend
 * @param {String} schoolId - the id of the school that comes from backend
 * @param {String} impressionGuid - the backend guid returned from either handleImpressionKeyMapping or _getSchoolListings
 * @param {String} viewLocation - the location on the result card that this event was fired for
 * @param {Array} programGuids - the guids of the programs that were clicked or viewed
 */
export function trackClickPortalImpression({
  schoolId,
  impressionGuid,
  programGuids,
  viewLocation,
}) {
  // IF CHANGING THIS, CHANGE processQueue in schoolImpressionQueue
  const payload = {
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/ClickPortal/RecordView`,
    body: {
      viewLocation,
      schoolImpressionGuid: impressionGuid,
      schoolDegreeInfoGuids: programGuids,
    },
  };

  if (!impressionGuid) {
    _addToImpressionQueue(schoolId, payload);
    return;
  }

  request(payload).catch((error) => {
    LogError(error, { impressionGuid });
    // don't throw, we don't want any UI error due to failing to track
  });
}

/**
 * @param {Object} filters - see CLICK_PORTAL_SEARCH_FILTERS in consts.js
 * @param {String} siteType - the type of site we are on
 * @param {?String} sessionId - the user session id
 * @param {Object} meta - meta data
 */
export default async function schoolListingsResultsHandler(
  filters,
  siteType,
  sessionId,
  /* meta */ { isPersonalized, geoLocation, originalUrl } = {}
) {
  try {
    // Simulate 5% control. We should never fetch control from cache
    // turning this off for now due to perf issues. We will port this logic to possibly cloudflare worker
    // const isControlGroup = random(1, 10) > 9.5;
    const isControlGroup = false;

    const schoolResults = await _getSchoolListings(
      filters,
      isControlGroup,
      siteType,
      sessionId,
      /* meta */ { isPersonalized, geoLocation, originalUrl }
    );

    return {
      ...schoolResults,
      error: '',
      isLoading: false,
    };
  } catch (error) {
    LogError(error);
    console.log(error);
    return {
      error: 'Sorry something went wrong, please try another selection',
      message: error.message,
    };
  }
}

/**
 * @summary get school listing from query
 * @param {Object|URLSearchParams} query - query params
 * @param {Object} microSiteTaxonomyMap - micro site taxonomy map
 */
export async function getSchoolListingResultsFromQuery(
  query,
  microSiteTaxonomyMap,
  { isPersonalized, geoLocation, sessionId, siteType, originalUrl } = {}
) {
  if (!sessionId && siteType === 'microPortal') {
    LogError('no sessionId on Microportal');
  }

  const filters = {
    [FILTER_KEY]: query[QUERY_PARAMS.FILTER_KEY_PARAM],
    ...getFormSelectionObjectsFromQuery(query, microSiteTaxonomyMap),
  };
  const results = await schoolListingsResultsHandler(
    filters,
    siteType,
    sessionId,
    /* meta */ { isPersonalized, geoLocation, originalUrl }
  );
  const hasFormQueryParams =
    query[QUERY_PARAMS.PARENT_CAT_GUID_PARAM] &&
    query[QUERY_PARAMS.DEGREE_GUID_PARAM] &&
    query[QUERY_PARAMS.CATEGORY_GUID_PARAM];
  let currentSelection;

  // Its possible on the server we get form values to select from API
  // This is also done on the browser in the GlobalReducer in case of client side routing
  // TODO: [T1-9137] lets consolidate this logic and remove it from the reducer.
  //       When getting and setting the results on the browser lets
  //       just set the current selection then as well
  if (results?.filterKeyDerivedValues?.hasDerivedValues) {
    currentSelection = getFormSelectionObjectsFromQuery(
      results.filterKeyDerivedValues,
      microSiteTaxonomyMap
    );
  } else if (hasFormQueryParams) {
    currentSelection = getFormSelectionObjectsFromQuery(
      query,
      microSiteTaxonomyMap
    );
  }
  // it's possible that the backend sends a default result set back if the selection was invalid
  // so unless all three values are still populated, assume there's no currentSelection

  if (
    currentSelection &&
    Object.values(currentSelection).every((selection) => selection?.value)
  ) {
    return {
      ...results,
      currentSelection,
    };
  }

  return results;
}
