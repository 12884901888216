/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import ReactSelect from 'components/base/selects/base-select/ReactSelect';
import Rating from 'components/base/rating/Rating';
import CloseBtn from 'components/base/closeBtn/CloseBtn';
import MultiSchoolSelectContext from 'components/base/inputs/multiSchoolSelect/multiSchoolSelectContext';
import { Terms } from 'components/base/inputs/multiSchoolSelect/Terms';
import SchoolLogo from '../SchoolLogo';
import { SelectionCardActions } from './SelectionCardActions';

interface SelectionCardExpandedProps {
  id: string;
}
MultiSchoolSelectContext;
export function SelectionCardExpanded(
  props: SelectionCardExpandedProps
): React.JSX.Element {
  const { id } = props;
  const { handleCloseCard, handleProgramChange, state } = useContext(
    MultiSchoolSelectContext
  );

  const {
    selectedProgram,
    schoolInfo: {
      schoolLogo,
      label,
      featuredImage,
      matchingProgramOptions: options,
      rating,
      terms,
    },
  } = state[id];

  return (
    <div className="selectionCard__back">
      <span className="selectionCard__closeBtn">
        <CloseBtn onClick={() => handleCloseCard(id)} />
      </span>
      <div className="selectionCard__featuredImage">
        <img src={featuredImage.mobile} alt="" />
      </div>
      <div className="selectionCard__body">
        <div className="selectionCard__meta">
          <div className="selectionCard__logo">
            <SchoolLogo
              logo={schoolLogo}
              className="selectionCard__logoImg"
              alt={`${label} logo`}
            />
          </div>
          <div className="selectionCard__rating">
            <div className="selectionCard__ratingLabel">
              Editor&#39;s Rating
            </div>
            <Rating rating={rating} id={`rating${label}`} />
          </div>
        </div>
        <div className="selectionCard__content">
          <h4 className="selectionCard__title">{label}</h4>
          <div className="selectionCard__formCtrl">
            <div className="selectionCard__formLabel">
              <label htmlFor="selectCardSelection">Best Matching Program</label>
            </div>
            <div className="selectionCard__formField">
              <ReactSelect
                options={options}
                value={selectedProgram}
                onChange={(program) => handleProgramChange(id, program)}
                name="selectCardSelection"
              />
            </div>
          </div>
          <div className="selectionCard__terms">
            <Terms terms={terms} />
          </div>
          <div className="selectionCard__termsHelpText">
            {`Please accept the terms and conditions for ${label}`}
          </div>
        </div>
        <div className="selectionCard__actions">
          <SelectionCardActions
            id={id}
            showSelect={false}
            showSubmit
            showCheckbox={false}
          />
        </div>
      </div>
    </div>
  );
}
