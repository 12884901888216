/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import GlobalContext from 'hooks/contexts/GlobalContext';
import useBasicFormWizardHandler from 'hooks/custom/forms/basic-wizard/useBasicFormWizardHandler';
import { numOrStrPropType, QuestionsPropType } from 'utils/propTypes';
import {
  FLOODLIGHT_EVENTS,
  trackFloodlightActivity,
  trackResumeModal,
  trackWizardStarted,
} from 'utils/trackingFunctions';
import { scrubPii } from 'utils/formValuesUtils';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import ThankyouStep from 'components/form-wizards/basic-form/ThankyouStep';
import {
  handleBrowserBackButton,
  handleTaxonomyPreSelect,
} from 'components/form-wizards/basic-form/utils';
import { scrollTo } from 'utils/generalUtils';
import { QUERY_PARAMS } from 'consts';
import { LeadEvalTokenRequestSideEffect } from 'hooks/custom/forms/basic-wizard/reducer-functions/side-effects/LeadEvalTokenRequestSideEffect';

const {
  CATEGORY_GUID_PARAM,
  PARENT_CAT_GUID_PARAM,
  PROGRAM_GUID_PARAM,
  PROGRAM_TITLE_PARAM,
} = QUERY_PARAMS;

// TODO: Clean all this logic up
export default function FormContainerV1(props) {
  // note that stepsCount is not steps.length. see triadToPropsTransform.
  const { Form, title, successRedirectUrl, disableSideEffects, ...rest } =
    props;
  const {
    userData,
    taxonomyValues,
    microSiteTaxonomyMap,
    windowSize: { currentBreakpoint },
    actions,
    formSettings: { success },
    sessionInfo: { floodLightActivityFilters },
    siteMeta: { floodlightActivityValues },
  } = useContext(GlobalContext);

  const router = useRouter();

  const formData = useBasicFormWizardHandler(
    props,
    currentBreakpoint,
    userData.sessionFormValues,
    userData.linkedSessionFormValues,
    {
      floodLightActivityFilters,
      floodlightActivityValues,
      isClickUser: floodLightActivityFilters.isClickUser,
    }
  );

  const {
    allQuestionsInForm,
    ctaText,
    currentAction,
    currentStepIndex,
    formStatus,
    formValues,
    handleBack,
    handleChange,
    handleSubmit,
    handleResumeForm,
    isThankyouPage,
    isFirstStep,
    isLastStep,
    initForm,
    updateFormValues,
    fieldNameMap,
    setFormValues,
    setFieldError,
    reconcileStep,
    formConfigs,
  } = formData;

  const taxonomyPreselectData = {
    userData,
    taxonomyValues,
    formStatus,
    allQuestionsInForm,
    microSiteTaxonomyMap,
    formValues,
  };

  const { isLoadingActive, isProcessing, isDirty, willAutoAdvance } =
    formStatus;

  const triggerResumeModal = () => {
    trackResumeModal({ isShow: true });
    return actions.setResumeModalProps({
      isOpen: true,
      title: 'Welcome Back',
      body: 'We saved your answers from a previous visit. Would you like to pick up where you left off?',
      onCancel: ({ interaction }) => {
        actions.setResumeModalProps({ isOpen: false });
        trackResumeModal({ interaction });
        handleResumeForm(false);
      },
      onSubmit: ({ interaction }) => {
        actions.setResumeModalProps({ isOpen: false });
        trackResumeModal({ interaction });
        handleResumeForm(true);
      },
    });
  };

  // When isLoadingActive is toggled view the current status of form and push forward if possible
  useEffect(() => {
    if (
      !isLastStep &&
      !isProcessing &&
      !isLoadingActive &&
      isDirty &&
      !willAutoAdvance
    ) {
      // if the form is not processing and is dirty, then we can push forward
      handleSubmit();
    }
  }, [isLoadingActive]); // DO NOT ADD ANY OTHER DEPENDENCIES TO THIS USE EFFECT, WE ONLY CARE ABOUT WHEN ISLOADINGACTIVE IS TOGGLED

  useEffect(() => {
    if (formStatus.isDirty) {
      actions.updateGlobalFlags({ isFormDirty: true });
      trackWizardStarted(rest.schoolCode, rest.variant);
    }
    if (formStatus.errors.isFormError || isThankyouPage) {
      scrollTo(0);
    }
    if (isThankyouPage) {
      actions.updateGlobalFlags({ isThankyouPage });
    }
  }, [formStatus.isDirty, formStatus.errors, currentStepIndex, isThankyouPage]);

  useEffect(() => {
    if (formStatus.isDirty) {
      trackFloodlightActivity(
        FLOODLIGHT_EVENTS.FORM_WIZARD_STARTED,
        floodlightActivityValues,
        floodLightActivityFilters
      );
    }
  }, [formStatus.isDirty]); // DO NOT ADD ANY OTHER DEPENDENCIES TO THIS USE EFFECT, WE ONLY CARE ABOUT WHEN isDirty IS TOGGLED

  useEffect(() => {
    actions.updateGlobalFlags({
      isFirstStep,
      isResumeSessionAcknowledged: formStatus.isResumeSessionAcknowledged,
    });
  }, [isFirstStep, formStatus.isResumeSessionAcknowledged]);

  // Clear PII if user stays on screen for over an hour
  useEffect(() => {
    const sessionTimer = setTimeout(() => {
      if (formStatus.isDirty) {
        initForm();
        const cleanFormValues = scrubPii(formValues, fieldNameMap);
        actions.updateUserData({ sessionFormValues: cleanFormValues });
        setFormValues(cleanFormValues);
      }
    }, 3600000);

    return () => clearTimeout(sessionTimer);
  }, [formStatus.isDirty, formValues]);

  // Used to pop the resume modal when the correct conditions are met
  useEffect(() => {
    if (formStatus.isFormResumeEligible) {
      triggerResumeModal(handleResumeForm);
    }
  }, [formStatus.isFormResumeEligible]);

  // TODO: we can delete this and update the hook to set these values now
  // when the user session data comes in from the API populate the form
  useEffect(() => {
    if (userData.sessionFormValues && !formStatus.isFormPrefilled) {
      updateFormValues(userData.sessionFormValues, {
        isFormPrefilled: true,
        linkedSessionFormValues: userData.linkedSessionFormValues,
      });
    }
  }, [userData.sessionFormValues, userData.linkedSessionFormValues]);

  // This is to handle a user being linked from other micro sites. We may need to not show them some questions depending on the site they are on
  useEffect(() => {
    if (Object.keys(formStatus.linkedSessionFormValues).length) {
      reconcileStep();
    }
  }, [formStatus.linkedSessionFormValues]);

  // each time the user changes a value on the degree search this will run, also once on page load.
  useEffect(() => {
    // note we don't want to update subject if form is dirty, as per reqs
    if (!formStatus.isDirty) {
      // its possible that the user selected an area of interest on a vertical page, in this case we need to push them back
      userData.parentCategorySelection && initForm();
      handleTaxonomyPreSelect(taxonomyPreselectData, handleChange);
    }
  }, [userData.parentCategorySelection, formStatus.isInitializing]);

  useEffect(() => {
    // it could be this happens on page load, full page form wizard. In that case the above one takes care of this along with other cases
    if (!userData.isLoadedFromCache) {
      handleTaxonomyPreSelect(taxonomyPreselectData, handleChange);
    }
  }, [userData.programOfInterest]);

  // navigation can be controlled by the browser back and forward buttons
  useEffect(() => {
    function triggerNavigation(event) {
      handleBrowserBackButton(event, {
        isInitializing: formStatus.isInitializing,
        currentStepIndex,
        currentAction,
        handleBack,
        handleSubmit,
      });
    }

    window.addEventListener('popstate', triggerNavigation);

    return () => {
      window.removeEventListener('popstate', triggerNavigation);
    };
  }, [currentStepIndex, formStatus.isInitializing]);

  // This gives us the ability to populate a user's program selection from another page's redirect
  useEffect(() => {
    const programTitle = router.query[PROGRAM_TITLE_PARAM];
    const programGuid = router.query[PROGRAM_GUID_PARAM];
    const categoryGuid = router.query[CATEGORY_GUID_PARAM];
    const parentCategoryGuid = router.query[PARENT_CAT_GUID_PARAM];
    if (programTitle && programGuid && categoryGuid && parentCategoryGuid) {
      // Set Program, Subject, Concentration
      actions.updateUserData({
        programOfInterest: {
          title: programTitle,
          guid: programGuid,
          category: [categoryGuid],
          parentCategory: [parentCategoryGuid],
        },
        isLoadedFromCache: true,
      });
    } else if (parentCategoryGuid) {
      // Set Subject
      actions.updateUserData({
        parentCategorySelection: {
          value: parentCategoryGuid,
        },
        isLoadedFromCache: true,
      });
    }
  }, []);

  const getNextButtonText = () => {
    if (isFirstStep) {
      return 'Next Step';
    }
    if (isLastStep) {
      return 'Submit';
    }
    return ctaText;
  };

  // Just render text on thank you page
  if (isThankyouPage && !successRedirectUrl) {
    return (
      <div className="basicFormWizard">
        <div className="basicFormWizard__container">
          <ThankyouStep
            successText={success?.text}
            cta={success?.cta}
            selectedProgram={formValues?.Program?.label}
          />
        </div>
      </div>
    );
  }

  return (
    <BasicFormWizardContext.Provider
      value={{
        formValues,
        handleChange,
        formStatus,
        allQuestionsInForm,
        handleSubmit,
        setFormValues,
        setFieldError,
        formConfigs,
        fieldNameMap,
      }}
    >
      <Form
        {...rest}
        {...formData}
        currentBreakpoint={currentBreakpoint}
        title={userData.programOfInterest ? 'Request Info' : title}
        isNextDisabled={formStatus.isLoadingActive}
        getNextButtonText={getNextButtonText}
      />
      {!disableSideEffects && (
        <LeadEvalTokenRequestSideEffect
          formState={formData}
          actions={formData.actions}
        />
      )}
    </BasicFormWizardContext.Provider>
  );
}

FormContainerV1.propTypes = {
  disableAutoSelect: PropTypes.bool,
  disableSideEffects: PropTypes.bool,
  Form: PropTypes.func.isRequired,
  id: numOrStrPropType.isRequired,
  title: PropTypes.string,
  triggerResumeModal: PropTypes.func,
  variant: PropTypes.string,
  stepsCount: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subTitle: PropTypes.string,
      heading: PropTypes.string,
      questions: QuestionsPropType,
      progressMeter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      trueStepIndex: PropTypes.number,
    })
  ),
  successRedirectUrl: PropTypes.string,
};
