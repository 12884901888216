import React, { useEffect, useState } from 'react';

interface MultiStepLoaderProps {
  duration: number; // Duration in milliseconds for the entire animation sequence
  isProcessing: boolean;
}

interface ListItemState {
  id: number;
  title: string;
  visible: boolean;
  progress: number;
}

export function MultiStepLoader(
  props: MultiStepLoaderProps
): React.JSX.Element {
  const { duration = 1500, isProcessing } = props;
  const [listItems, setListItems] = useState<ListItemState[]>([
    {
      id: 1,
      title: 'Searching schools that offer Accounting programs',
      visible: false,
      progress: 0,
    },
    {
      id: 2,
      title: 'Matching with your experience',
      visible: false,
      progress: 0,
    },
    {
      id: 3,
      title: 'Checking program availability',
      visible: false,
      progress: 0,
    },
  ]);

  // Function to animate the progress bar
  function animateProgressBar(
    index: number,
    itemDuration: number,
    onComplete: () => void
  ): void {
    let progress = 0;
    const progressInterval = setInterval(() => {
      let progressIncrement = 10;

      // If it's the last item and `isProcessing` is true and progress is at or above 50%, slow down the progress
      if (index === listItems.length - 1 && isProcessing && progress >= 50) {
        progressIncrement = 1; // Slower progress increment
      }

      progress += progressIncrement;

      setListItems((prevItems) => {
        const newItems = [...prevItems];
        newItems[index] = { ...newItems[index], progress };
        return newItems;
      });

      if (progress >= 100) {
        clearInterval(progressInterval);
        onComplete(); // Trigger the next animation step
      }
    }, itemDuration / 10); // Progress steps of 10% at each interval (slowed down for last item if conditions are met)
  }

  useEffect(() => {
    let currentItemIndex = 0; // New variable to track current item
    const intervalDuration = duration / listItems.length;

    function animateNextItem(): void {
      if (currentItemIndex < listItems.length) {
        // Show the next item
        setListItems((prevItems) => {
          const newItems = [...prevItems];
          newItems[currentItemIndex] = {
            ...newItems[currentItemIndex],
            visible: true,
          };
          return newItems;
        });

        // Start the progress bar animation
        animateProgressBar(currentItemIndex, intervalDuration, () => {
          currentItemIndex += 1;
          if (currentItemIndex < listItems.length) {
            animateNextItem();
          }
        });
      }
    }

    // Start the animation sequence
    animateNextItem();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  return (
    <div className="multiStepLoader">
      <div className="multiStepLoader__title">
        Alright, let me find the perfect schools for you...
      </div>
      <ul className="multiStepLoader__items">
        {listItems.map((item) => {
          return (
            <li
              key={item.id}
              className={`multiStepLoader__item ${
                item.visible ? 'multiStepLoader__item--isVisible' : ''
              }`}
              style={
                {
                  '--multiStepLoaderProgress': `${item.progress}%`,
                } as React.CSSProperties
              }
            >
              <div className="multiStepLoader__itemTitle">{item.title}</div>
              <div className="multiStepLoader__itemProgress">
                <span className="multiStepLoader__progressText">
                  {item.progress}%
                </span>
              </div>
              <div className="multiStepLoader__itemIcon" />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
