import get from 'lodash/get';
import { QUESTION_IDS } from 'consts';
import { isQuestionOptionsCacheable } from 'utils/generalUtils';
import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import request from 'utils/request';
import { questionsToMap } from 'utils/formValuesUtils';
import {
  getQuestionOptionsPayload,
  GetQuestionOptionsParsedResponse,
  GetQuestionOptionsRawResponse,
} from 'types';

const { SUBJECT_0F_INTEREST } = QUESTION_IDS;

/**
 * @summary given the current state of the form and the current school we may need to get options for a question
 */
export function getQuestionOptions(
  payload: getQuestionOptionsPayload,
  schoolCode: string,
  variant: string,
  endpoint = '/questionOption'
): GetQuestionOptionsParsedResponse {
  const replies = get(payload, 'questionReplies', []).map(
    ({ questionAnswer, questionId }) => ({
      questionAnswer,
      questionId,
    })
  );

  const neededOptions = get(payload, 'requestedOptions', []);
  const endpointUrl = `${TRIAD_PROXY_ROUTE}${endpoint}`;
  let cacheKey = '';

  // if options request is just for subject then cache
  if (isQuestionOptionsCacheable(neededOptions)) {
    // not taking variant into account here because we are only caching for subject which is the same on all variants
    cacheKey = `${schoolCode}_questionOptions_${SUBJECT_0F_INTEREST}`;
  }

  // BUG: [T1-9320] If API returns 0 results we should not cache.
  return request({
    method: 'post',
    url: endpointUrl,
    cacheKey,
    body: {
      schoolCode,
      templateName: variant,
      requestedOptions: neededOptions,
      questionReplies: replies,
    },
  })
    .then(({ questions }: GetQuestionOptionsRawResponse) => {
      return questionsToMap(questions);
    })
    .catch((error: Error) => {
      throw error;
    });
}
